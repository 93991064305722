import { navigate } from "gatsby"
import { Component } from "react"

class TanjungMBPPPage extends Component {
  componentDidMount() {
    navigate("/events")
  }

  render() {
    return null
  }
}

export default TanjungMBPPPage
